<template>
  <vuestic-widget :loading="loading">
    <vuestic-modal
      :isOpen="isOpenModalRestrict"
      @cancel="onRedirectInstalls"
      :cancelShown="true"
      :okShown="false"
      cancelText="Close"
      cancelClass="btn btn-primary"
    >
      <span slot="title" class="font-weight-bold">No Installs Available</span>
      <div class="d-block text-center">
        <span class="no-install-available-span">You don't have any installs available. If you would like to add a new install you must delete an existing install or upgrade your subscription to add additional installs. Please contact us at <a class="link" v-bind:href="'mailto:' + agencyOwnerEmail">{{ agencyOwnerEmail }}</a> If you would like to add an additional install.</span>
      </div>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import moment from 'moment-timezone'
import timezones from 'timezones.json'
import { defaultInstallData } from '../../../constants'
import cloneDeep from 'clone-deep';

export default {
  created () {
    if (this.$route.path.match(/add/)) {
      this.loading = true
      this.$api.businessCanAddInstall().then((data) => {
        this.loading = false
        if(!data.data.success) {
          this.isOpenModalRestrict = true
        } else {
          this.saveInstall()
        }
      },
      ).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    this.loading = false;
    this.form = cloneDeep(defaultInstallData);
    this.check_if_install_modified = false

    if (!this.$route.params.id) {
      this.form.properties.type = { ...this.defaultNotificationType }
      this.form.properties.callingType = { ...this.defaultCallingType }
      this.form.widget_type = this.defaultBusinessWidgetType

      if (this.localTimeZone) {
        this.form.properties.timezone = {
          value: this.localTimeZone.value,
          description: this.localTimeZone.description,
        }
      }
    }
  },
  computed: {

    defaultBusinessWidgetType () {
      if (this.$store.state.auth.user && this.$store.state.auth.user.business)
        return this.$store.state.auth.user.business.widget_type
      return 1
    },

    user () {
      return this.$store.state.auth.user
    },

    agencyOwnerEmail () {
      return this.$store.state.auth.user.agency && this.$store.state.auth.user.agency.email
    },

    localTimeZone () {
      const momentTimeZone = moment.tz.guess()
      return this.timeZonesForSelect.find(t => {
        return t.value === momentTimeZone
      })
    },

    timeZonesForSelect () {
      const result = []
      this.timezones.forEach(record => {
        record.utc.forEach(timezoneName => {
          const timezone = moment().tz(timezoneName)
          result.push({
            value: timezoneName,
            description: `(UTC${timezone.format('Z')}) ${timezoneName}`,
          })
        })
      })
      return result
    },
  },

  data () {
    return {
      loading: true,
      isOpenModalRestrict: false,
      form: {},
      timezones,
      defaultNotificationType: {
        id: 1,
        description: 'Phone',
      },
      defaultCallingType: {
        id: 1,
        description: 'Call all at once',
      },
    }
  },
  methods: {
    onRedirectInstalls() {
      this.isOpenModalRestrict = false
      this.$router.push({ name: 'business.installs' })
    },
    async saveInstall () {
      const form = this.form
      form.email = this.user.email

      return new Promise(async (resolve, reject) => {
        this.$store.dispatch('install/createInstall', {
          data: form
        }).then(( data ) => {
          if (data.success) {
            this.$router.push({ name: 'business.installs.edit', params: { id: data.data.id } })
            resolve(true)
          } else {
            resolve(false)
          }
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
</script>

<style lang="scss">
  .wizard {
    fieldset.border {
      legend {
        color: #333;
      }
    }

    .settings {
      .vuestic-switch {
        margin-top: 1rem;
      }
    }

    .officeHours .row {
      margin-bottom: 0.5em;

      .display-time {
        border-radius: 15px;
      }

      .form-group {
        margin-bottom: 1rem;
      }

      .dropdown-menu .dropdown-item {
        max-width: 100%;
      }
    }

    .form-group-w-btn {
      .control-label {
        font-size: 0.8rem;
      }

      & ~ .help {
        display: block;
        margin-top: -32px;
      }
    }

    .control-label {
      text-transform: uppercase;
    }

    .wizard-body {
      padding: 0px !important;

      .form-wizard-tab-content {
        align-items: normal !important;
      }
    }

    .wizard-step {
      height: 2.6rem !important;

      .wizard-step-label {
        font-size: 1rem !important;
        text-transform: uppercase !important;
      }
    }

    textarea.code {
      border: none;
      background-color: $rb-gray;

      &:active,
      &:focus {
        outline-color: white !important;
      }
    }
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .flatpickr-time input:hover,
  .flatpickr-time .flatpickr-am-pm:hover,
  .flatpickr-time input:focus,
  .flatpickr-time .flatpickr-am-pm:focus {
    background: transparent !important;
  }

  .lelabel {
    display: block;
    text-align: left;
    clear: both;
    font-size: 0.6rem;
    color: #333;
    font-weight: 600;
    text-transform: uppercase;
    top: -0.6rem;
    left: 0;
  }

  .vuestic-file-upload-field--dropzone {
    padding: 0 .7rem !important;
    height: 2.4rem !important;
  }

  .input-group-company-logo {
    .vuestic-file-upload-single {
      height: 60px;

      button {
        display: none;
      }
    }

  }

  .btn-text {
    color: black;
  }

  .logo-preview {
    text-align: left;
    img {
      max-width: 100%;
      max-height: 100px;
      margin: 10px auto;
    }
    .actions {
      text-align: left;
    }
  }

  .overlay-dim {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;

    position: absolute;
    top:0;
    left:0;
    bottom: 0;
    right:0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .fa-2x {
    line-height: 2em;
  }
</style>
<style scoped>
  select.form-control{
    width: 100% !important;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    border-radius: 4px !important;
    padding: 0 10px;
    height: 35px !important;
    background-image: none !important; 
  }
  select.form-control:focus{
    outline: none !important;
    box-shadow: none !important;
  }
  .custom-input-icon{
    top: 10px !important;
    right: 10px !important;
    z-index: 3 !important;
  }
  .no-install-available-span {
    display: block;
    font-size: 16px;
  }
</style>